<template>
  <vue-perfect-scrollbar
    :settings="{suppressScrollX: true, wheelPropagation: false}"
    class="h-100 rtl-ps-none ps scroll user-drawer"
    style="height: 100%;"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'primary'"
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6
            class="ma-0"
            :class="$vuetify.theme.dark ? 'white--text' : 'secondary--text'"
          >
            {{ $t("user.myAccount") }}
          </h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5 mt-5">
      <div class="d-flex align-center mb-10">
        <div>
          <p class="text-body-2 text--disabled mb-0">
            {{ $t("common.name") }}
            <v-btn
              small
              class="ml-4"
              icon
              color="primary"
              @click="gotoUserDetail"
            >
              <v-icon
                small
                dense
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </p>
          <p class="font-weight-medium mb-3">
            {{ currentUser.name }}
          </p>
          <p class="text-body-2 text--disabled mb-0">
            {{ $t("common.email") }}
            <v-btn
              small
              class="ml-4"
              icon
              color="primary"
              @click="gotoUserDetail"
            >
              <v-icon
                small
                dense
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </p>
          <p class="font-weight-medium mb-3">
            {{ currentUser.email }}
          </p>
          <p class="text-body-2 text--disabled mb-0">
            {{ $t("user.role") }}
          </p>
          <p class="text-body-2 mb-2">
            {{ roleText }}
          </p>
          <p class="text-body-2 text--disabled mb-0">
            {{ $t("user.aboutMe") }}
            <v-btn
              small
              class="ml-4"
              icon
              color="primary"
              @click="gotoUserDetail"
            >
              <v-icon
                small
                dense
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </p>
          <p class="text-body-2 mb-2">
            {{ currentUser.aboutMe }}
          </p>
          <p class="text-body-2 text--disabled mb-0">
            {{ $t("common.language") }}
          </p>
          <v-radio-group
            v-model="language"
            column
            class="mt-0"
          >
            <v-radio value="en">
              <template #label>
                <span
                  class="text-body-2 mb-0"
                  :class="{'primary--text': language === 'en'}"
                >
                  {{ $t('common.english') }}
                </span>
              </template>
            </v-radio>
            <v-radio value="se">
              <template #label>
                <span
                  class="text-body-2 mb-0"
                  :class="{'primary--text': language === 'se'}"
                >
                  {{ $t('common.swedish') }}
                </span>
              </template>
            </v-radio>
          </v-radio-group>
          <p class="text-body-2 text--disabled mb-0">
            {{ $t('common.themeColorMode') }}
          </p>
          <v-btn
            v-if="!$vuetify.theme.dark"
            class="mr-4 mb-4"
            color="dark"
            small
            fab
            dark
            @click="changeDarkMode(true)"
          >
            <v-icon>mdi-weather-night</v-icon>
          </v-btn>
          <v-btn
            v-else
            class="mr-4 mb-4"
            color="white"
            small
            fab
            @click="changeDarkMode(false)"
          >
            <v-icon color="warning">
              mdi-white-balance-sunny
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'UserDrawer',

    props: {},

    data () {
      return {
        language: '',
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        isAdminUser: 'isAdminUser',
        isPartnerAdminUser: 'isPartnerAdminUser',
      }),

      roleText () {
        if (this.isAdminUser) {
          return this.$t('user.systemManager')
        }

        if (this.isPartnerAdminUser) {
          return this.$t('user.companyManager')
        }

        return this.$t('user.user')
      },
    },

    watch: {
      language (val, oldVal) {
        this.$i18n.locale = this.language
        localStorage.setItem('language', this.language)

        if (!oldVal) {
          return
        }

        window.location.reload()
      },
    },

    created () {
      this.language = localStorage.getItem('language') || 'en'
    },

    methods: {
      gotoUserDetail () {
        if (this.$route.name === 'Profile') {
          return
        }

        this.$router.push('/profile')
      },

      changeDarkMode (data) {
        this.$vuetify.theme.dark = data
      },
    },
  }
</script>

<style lang="scss">
  .user-drawer {
    header {
      border-bottom: 1px solid #FFF !important;
    }
  }
</style>
